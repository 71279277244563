<template>
  <div class="ac2">
    <div class="mxw-container">
      <!-- 循环项 -->
      <div class="mxw-item">
        <!-- 上层 -->
        <div class="item-s">
          <!-- 左侧 图片+信息 -->
          <div class="item-s-l">
            <img src="" alt="">

            <div class="l-info">
              <h3>长沙安采科技有限公司</h3>
              <p>安采账号：长沙安采科技有限公司</p>
              <p>安采账号：长沙安采科技有限公司</p>
              <p>安采账号：长沙安采科技有限公司</p>
              <p>安采账号：长沙安采科技有限公司</p>
            </div>
          </div>
          <!-- 右侧 下载按钮 -->
          <div class="item-s-r">
            <div class="r-btn">
              下载APP
            </div>
          </div>
        </div>

        <!-- 下层 -->
        <div class="item-x">
          <img src="/imgs/app/1.jpg" alt="">
          <img src="/imgs/app/2.jpg" alt="">
          <img src="/imgs/app/3.jpg" alt="">
          <img src="/imgs/app/1.jpg" alt="">
        </div>

        <!-- 文字 -->
        <h3 class="item-title">
          软件介绍
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";

export default {
  name: "ac1",
  components: {},
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "百融至信";
  },
  methods: {
    init1() {
      this.axios.get("/mock/index2.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.myData = data.splice(0, 3);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // alert("资讯每篇2元，点击确定购买");
      // this.showModal = true;

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条2元，点击确定登录购买");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.ac2 {

  .mxw-container {
    width: 1226px;
    margin: 15px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;


    // 循环项
    .mxw-item {
      width: 100%;

      // 上层
      .item-s {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: space-between;


        // 左侧
        .item-s-l {
          width: 700px;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 25px;
          box-sizing: border-box;

          img {
            width: 200px;
            height: 200px;
            background-color: #fff;
            display: block;
            margin-right: 55px;
          }

          .l-info {
            h3 {
              font-size: 21px;
              margin-bottom: 10px;
            }

            p {
              color: #888;
              font-size: 12px;
              margin: 15px 0;
            }
          }
        }

        // 右侧
        .item-s-r {
          width: 400px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 25px;
          box-sizing: border-box;

          .r-btn {
            width: 150px;
            height: 50px;
            background-color: #2b54e9;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
            border-radius: 35px;
          }
        }
      }

      // 下层
      .item-x {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 25px 0;
        box-sizing: border-box;
        border-top: 2px solid #ccc;

        img {
          height: 500px;
          margin: 0 10px;
        }
      }

      .item-title {
        margin: 35px 0 15px 0;
        font-size: 18px;
        padding-bottom: 15px;
        box-sizing: border-box;
        border-bottom: 2px solid #ccc;
      }
    }
  }
}
</style>
