<template>
  <div class="n-footer">
    <div class="container">
      <!-- 关于我们 -->
      <div class="f-info" style="display: none;">
        <!-- 标题 -->
        <h2>关于我们</h2>
        <p>
          北京识君互娱网络有限公司于2017年06月08日成立，公司经营范围包括：互联网信息服务；从事互联网文化活动；货物进出口；设计、制作、代理、发布广告等。
        </p>
      </div>
      <!-- 网站导航 -->
      <div class="f-list">
        <h2>网站导航</h2>
        <a href="javascript:;" @click="goHome()">网站首页</a>
        <a href="javascript:;" @click="goAc()">行业资讯</a>
        <!-- <a href="javascript:;" @click="goInfo()">关于我们</a> -->
        <a href="javascript:;" @click="goXy()">用户协议</a>
      </div>
      <!-- 联系我们 -->
      <div class="f-phone">
        <h2>联系我们</h2>

        <p>地址： 南京市建邺区嘉陵江东街18号4栋916室 </p>
        <p>
          ICP备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">苏ICP备2024142056号-1
          </a>
        </p>
      </div>
      <!-- 文字logo -->
      <div class="f-logo">
        <h2>南京命命鸟科技有限公司  </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 首页
    goHome() {
      this.$router.push("/");
    },
    // 资讯
    goAc() {
      this.$router.push("/ac1");
    },
    // 关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.n-footer {
  width: 100%;
  height: 255px;
  background-color: #292f32;
  position: relative;

  .container {
    height: 100%;
    @include flex();

    // 关于我们
    .f-info {
      width: 25%;

      h2 {
        color: #fff;
      }

      p {
        font-size: 12px;
        color: #888;
        line-height: 25px;
        margin-top: 15px;
      }
    }

    // 网站导航
    .f-list {
      h2 {
        color: #fff;
      }

      a {
        text-align: center;
        font-size: 14px;
        color: #888;
        margin: 15px 0;
        display: block;
      }
    }

    // 联系我们
    .f-phone {
      width: 34%;

      h2 {
        color: #fff;
        margin-bottom: 25px;
      }

      p {
        font-size: 14px;
        color: #888;
        margin: 15px 0;

        a {
          font-size: 14px;
          color: #888;
        }
      }
    }

    .f-logo {
      h2 {
        font-size: 21px;
        color: #fff;
      }
    }
  }
}
</style>
